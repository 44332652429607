<template>
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>

<div class="not">
  <h1>404</h1>
  <h2>Page not found</h2>
</div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";

import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  name: "404",
};
</script>
<style>
.not{
  text-align: center;
  min-height: calc(100vh - 230px);
  display: flex;
      flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ed4b4b;
}
.not h1{
 font-size: 120px;
 line-height: 1;
 margin: 0;
}
.not h2{
 font-size: 30px;
 line-height: 1;
}

</style>